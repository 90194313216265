<template>
  <base-material-card
    class="v-card--material-chart"
    v-bind="$attrs"
    v-on="$listeners"
    :color="cardColor"
  >
    <!-- hover-reveal -->

    <template v-slot:heading>
      <div>
        <canvas ref="chart"></canvas>
      </div>
    </template>

    <h3 class="card-title font-weight-light mt-2 ml-2">{{ tittle }}</h3>
    <p class="d-inline-flex font-weight-light ml-2 mt-1">{{ subText }}</p>
    <!-- <hr class="horizontal dark mb-4 mx-2" /> -->
    <template v-slot:actions>
      <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
      <span class="caption grey--text font-weight-light">{{ lastUpdateMessage }}</span>
    </template>
  </base-material-card>
</template>
<script>
import Chart from 'chart.js'

export default {
  data() {
    return {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      lastUpdate: Date.now(),
      actualDate: Date.now(),
    }
  },
  props: {
    cardColor: {
      type: String,
      default: 'white',
    },
    chartType: {
      type: String,
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '270',
    },
    legendPosition: {
      type: String,
      default: 'bottom', // Valor por defecto
    },
    subText: {
      type: String,
      default: undefined,
    },
    subTextColor: {
      type: String,
      default: undefined,
    },
    tittle: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.generateBarColors()
    this.renderChart()
    setInterval(this.updateData, 60000)
  },
  computed: {
    lastUpdateMessage() {
      const diff = Math.floor((this.actualDate - this.lastUpdate) / 60000) // Diferencia en minutos

      if (diff < 1) {
        return 'Actualizado hace menos de un minuto'
      } else if (diff === 1) {
        return 'Actualizado hace un minuto'
      } else if (diff < 60) {
        return `Actualizado hace ${diff} minutos`
      } else if (diff === 60) {
        return `Actualizado hace una hora`
      } else {
        const hours = Math.floor(diff / 60)
        return `Actualizado hace ${hours} horas`
      }
    },
  },
  methods: {
    generateBarColors() {
      const labels = this.dataset.data.labels
      this.backgroundColor = []
      this.borderColor = []
      for (let index = 0; index < labels.length; index++) {
        let r = Math.floor(Math.random() * 256)
        let g = Math.floor(Math.random() * 256)
        let b = Math.floor(Math.random() * 256)

        this.backgroundColor[index] = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + '0.5)'
        this.borderColor[index] = 'rgba(' + r + ', ' + g + ', ' + b + ')'
      }
    },

    generateRandomColor() {
      // Generar valores aleatorios para R, G, B y A entre 0 y 255
      var r = Math.floor(Math.random() * 256)
      var g = Math.floor(Math.random() * 256)
      var b = Math.floor(Math.random() * 256)
      // var a = Math.random().toFixed(2) // Opacidad aleatoria entre 0 y 1, redondeada a 2 decimales

      // Devolver el color en formato RGBA
      // return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')'
      return 'rgba(' + r + ', ' + g + ', ' + b + ')'
    },

    renderChart() {
      const ctx = this.$refs.chart.getContext('2d')
      const data = this.prepareData()

      const chartOptions = {
        legend: {
          position: this.legendPosition,
          display: false,
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: { animateScale: true },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 10,
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: 'rgba(255, 255, 255, 0.5)',
              },
              ticks: {
                fontColor: 'rgba(255, 255, 255, 0.8)',
                fontSize: 11,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                display: true,
                // color: '#f8f9fa',
                padding: 10,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: 'rgba(255, 255, 255, 0.5)',
              },
              ticks: {
                fontColor: 'rgba(255, 255, 255, 0.8)',
                fontSize: 11,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                suggestedMin: 0,
                // suggestedMax: 60,
                beginAtZero: true,
                padding: 25,
              },
            },
          ],
        },
      }

      const chartConfig = {
        type: this.chartType === 'bar' ? this.chartType : 'line',
        data: {
          labels: data.labels,
          datasets: data.datasets,
        },
        options: chartOptions,
      }
      new Chart(ctx, chartConfig)
    },

    prepareData() {
      const labels = this.dataset.data.labels
      let datasets = []

      if (Array.isArray(this.dataset.data.series) && this.dataset.data.series.length > 0) {
        if (typeof this.dataset.data.series[0] === 'object') {
          datasets = this.dataset.data.series.map((serie) => ({
            barPercentage: 0.3,
            label: serie.label,
            data: serie.data,
            fill: this.chartType === 'line' ? false : null,
            backgroundColor: 'white',
            borderColor: 'white',
            borderWidth: this.chartType === 'bar' ? 1 : 4,
            pointRadius: this.chartType === 'line' ? 3.5 : null,
            pointBackgroundColor: this.chartType === 'line' ? 'white' : 'null',
          }))
          /* this.dataset.data.series.forEach((set) => {
            let color = this.randomColor()
            datasets.push({
              label: set.label,
              data: set.data,
              backgroundColor: color,
              borderColor: color,
              pointBackgroundColor: color,
              fill: false,
            })
          }) */
        } else {
          datasets.push({
            barPercentage: 0.3,
            data: this.dataset.data.series,
            fill: this.chartType === 'line' ? false : null,
            label: 'Data',
            backgroundColor: 'white',
            borderColor: 'white',
            borderWidth: this.chartType === 'bar' ? 1 : 4,
            pointRadius: this.chartType === 'line' ? 3.5 : null,
            pointBackgroundColor: this.chartType === 'line' ? 'white' : 'null',
          })
        }
      }

      return {
        labels,
        datasets,
      }
    },

    randomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    onDataUpdate() {
      // Aquí tendrías el código para actualizar los datos de la gráfica
      // Y después actualizas la marca de tiempo
      this.lastUpdate = Date.now()
    },
    updateData() {
      this.actualDate = Date.now()
    },
  },
}
</script>
