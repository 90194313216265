<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <!-- Material stats cards -->
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Reservas"
          value="184"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          sub-text="Get More Space..."
        />
      </v-col>
      <!-- Total de llegadas -->
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-airplane-landing"
          title="Llegadas"
          :value="transfersTypeCharts.llegadas.toString()"
          sub-icon="mdi-calendar"
          sub-icon-color="primary"
          sub-text="Traslados aeropuerto-hotel de hoy"
        />
      </v-col>
      <!-- Total de salidas -->
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          icon="mdi-airplane-takeoff"
          title="Salidas"
          :value="transfersTypeCharts.salidas.toString()"
          sub-icon="mdi-calendar"
          sub-icon-color="success"
          sub-text="Traslados hotel-aeropuerto de hoy"
        />
      </v-col>
      <!-- Total hotel hotel -->
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          icon="mdi-car-estate"
          title="Terrestre"
          :value="transfersTypeCharts.terrestre.toString()"
          sub-icon="mdi-calendar"
          sub-icon-color="info"
          sub-text="Traslados hotel-hotel de hoy"
        />
      </v-col>

      <!-- Graficas Material-->
      <!-- Reservas ultimos 7 dias -->
      <v-col v-if="dailySalesChart" cols="12" lg="4">
        <MaterialChart
          cardColor="accent"
          chartType="line"
          height="125"
          legendPosition="left"
          subText="Reservas de los últimos 7 dias"
          subTextColor="accent"
          tittle="Reservas semanales"
          :dataset="dailySalesChart"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <MaterialChart
          cardColor="error"
          chartType="bar"
          height="125"
          legendPosition="left"
          tittle="Ventas semanales"
          :dataset="dailyBookingsChart"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <MaterialChart
          cardColor="info"
          chartType="line"
          height="125"
          legendPosition="left"
          tittle="Reservas por zona"
          :dataset="dailyBookingsChart"
        />
      </v-col>

      <!-- Graficas Simples -->
      <v-col cols="12" lg="4">
        <SimpleChart
          v-if="devicesBookingsChart"
          chartType="doughnut"
          legendPosition="left"
          tittle="Reservas por dispositivos"
          :data="devicesBookingsChart.data.series"
          :labels="devicesBookingsChart.data.labels"
          :dataset="devicesBookingsChart"
        />
      </v-col>

      <v-col cols="12" lg="4">
        <SimpleChart
          v-if="paymentsChart"
          chartType="doughnut"
          legendPosition="left"
          tittle="Reservas por tipo de pago"
          :data="paymentsChart.data.series"
          :labels="paymentsChart.data.labels"
          :dataset="paymentsChart"
        />
      </v-col>

      <v-col cols="12" lg="4">
        <SimpleChart
          v-if="currencyChart"
          chartType="doughnut"
          legendPosition="left"
          tittle="Reservas por tipo de moneda"
          :data="currencyChart.data.series"
          :labels="currencyChart.data.labels"
          :dataset="currencyChart"
        />
      </v-col>

      <v-col cols="12" lg="6">
        <SimpleChart
          chartType="line"
          height="125"
          legendPosition="bottom"
          tittle="Reservas por proveedores"
          :data="proveedoresChart.data.series"
          :labels="proveedoresChart.data.labels"
          :dataset="proveedoresChart"
        />
      </v-col>
      <!-- Grafica de nube -->
      <v-col cols="12" lg="6">
        <WordCloudChart
          :words="words"
          :options="options"
          :size="size"
          title="Reservas por zonas"
          :word-style="wordStyle"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SimpleChart from './widgets/SimpleChart.vue'
import MaterialChart from './widgets/MaterialChart.vue'
import WordCloudChart from './widgets/WordCloudChart.vue'

export default {
  name: 'DashboardDashboard',
  components: {
    SimpleChart,
    MaterialChart,
    WordCloudChart,
  },
  data() {
    return {
      words: [
        ['Akumal', 10],
        ['Bahia Petempich', 7],
        ['Bahia Príncipe', 8],
        ['Bahia Príncipe', 9],
        ['Puerto Juárez', 6],
        ['Akumal', 10],
        ['Playa Mujeres', 17],
        ['IslaBlanca', 28],
        ['Playa Paraiso', 19],
        ['Puerto Morelos', 16],
        ['Cancún', 56],
      ],
      options: {
        rotations: 2,
        rotationAngles: [-45, 0],
      },
      size: [600, 400],
      wordStyle: {
        fontFamily: 'Arial',
        fontSize: '2em',
        fontWeight: 'bold',
        color: '#000',
        tooltip: true,
      },
      currencyChart: null,
      countryData: {
        US: 2920,
        DE: 1390,
        AU: 760,
        GB: 690,
        RO: 600,
        BR: 550,
      },
      dailyBookingsChart: {
        data: {
          labels: ['Mie', 'Jue', 'Vie', 'Sab', 'Dom', 'Lun', 'Mar'],
          series: [13, 24, 4, 9, 17, 22, 11],
        },
      },
      dailySalesChart: null,
      paymentsChart: null,
      devicesBookingsChart: null,
      emailsSubscriptionChart: {
        data: {
          labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
          series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            },
          ],
        ],
      },
      proveedoresChart: {
        data: {
          labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun'],
          series: [
            { label: 'Eos Travel', data: [50, 48, 26, 35, 49, 27] },
            { label: 'Legacy', data: [30, 28, 56, 15, 39, 7] },
            { label: 'Canada Transfers', data: [31, 41, 23, 32, 19, 17] },
          ],
        },
      },
      transfersTypeCharts: { llegadas: 0, salidas: 0, terrestre: 0 },
    }
  },

  mounted() {
    // this.obtenerReservasPorDispositivo()
    // this.obtenerReservasSemana()
    // this.obtenerTiposMoneda()
    // this.obtenerTiposTraslados()
    // this.obtenerTiposPago()
    this.obtenerEstadisticas()
  },

  computed: {},

  methods: {
    async obtenerEstadisticas() {
      let payload = {}
      this.$store
        .dispatch('dashboard/fetchStatistics', payload)
        .then((response) => {
          this.devicesBookingsChart = response.data.dispositivosReserva
          this.dailySalesChart = response.data.reservasSemanales
          this.currencyChart = response.data.tipoMonedasReserva
          this.transfersTypeCharts = response.data.tipoTrasladosReserva.data
          this.paymentsChart = response.data.tipoPagosReserva
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: `Ocurrio un error en reservas por semana ${err}`,
          })
        })
    },

    /**
     * Obtiene las reservas de la semana actual
     * @async
     * @function obtenerReservasSemana
     * @returns {Promise<void>}
     */
    async obtenerReservasSemana() {
      let payload = {}
      this.$store
        .dispatch('dashboard/fetchWeekReservations', payload)
        .then((response) => {
          this.dailySalesChart = response.data
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: `Ocurrio un error en reservas por semana ${err}`,
          })
        })
    },

    /**
     * Obtiene las reservas por dispositivo
     * @async
     * @function obtenerReservasPorDispositivo
     * @returns {Promise<void>}
     */
    obtenerReservasPorDispositivo() {
      let payload = {}
      this.$store
        .dispatch('dashboard/fetchDeviceTypes', payload)
        .then((response) => {
          this.devicesBookingsChart = response.data
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: `Ocurrio un error en reservas por dispositivo ${err}`,
          })
        })
    },

    /**
     * Obtiene las reservas por tipo de moneda
     * @async
     * @function obtenerTiposMoneda
     * @returns {Promise<void>}
     */
    obtenerTiposMoneda() {
      let payload = {}
      this.$store
        .dispatch('dashboard/fetchCurrencyTypes', payload)
        .then((response) => {
          this.currencyChart = response.data
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error en reservas por tipo pago',
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * Obtiene las reservas por tipo de pago
     * @async
     * @function obtenerTiposPago
     * @returns {Promise<void>}
     */
    obtenerTiposPago() {
      let payload = {}
      this.$store
        .dispatch('dashboard/fetchPaymentTypes', payload)
        .then((response) => {
          this.paymentsChart = response.data
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error en reservas por tipo pago',
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * Obtiene las reservas del dia por tipo de traslado
     * @async
     * @function obtenerTiposTraslados
     * @returns {Promise<void>}
     */
    obtenerTiposTraslados() {
      let payload = {}
      this.$store
        .dispatch('dashboard/fetchTransfersTypes', payload)
        .then((response) => {
          this.transfersTypeCharts = response.data.data
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error en reservas por tipo traslado',
            // Enviar a inicio de sesión
          })
        })
    },
  },
}
</script>
