<template>
  <v-card class="v-card--material-chart" v-bind="$attrs" v-on="$listeners" color="white">
    <v-card-title class="white--text mt-8">
      <h3 class="card-title font-weight-light mt-2 ml-2">{{ tittle }}</h3>
    </v-card-title>
    <v-card-text :style="{ height: fixedHeight }">
      <v-divider class="mt-2" />
      <template>
        <canvas ref="chart" style="max-height: 100%"></canvas>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import Chart from 'chart.js'

export default {
  data() {
    return {
      /* backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ], */
      systemBorderColor: [
        process.env.VUE_APP_PRIMARY,
        process.env.VUE_APP_SECONDARY,
        process.env.VUE_APP_ACCENT,
        process.env.VUE_APP_ERROR,
        process.env.VUE_APP_INFO,
        process.env.VUE_APP_SUCCESS,
        process.env.VUE_APP_WARNING,
      ],
      systemColor: [
        process.env.VUE_APP_PRIMARY + '80',
        process.env.VUE_APP_SECONDARY + '80',
        process.env.VUE_APP_ACCENT + '80',
        process.env.VUE_APP_ERROR + '80',
        process.env.VUE_APP_INFO + '80',
        process.env.VUE_APP_SUCCESS + '80',
        process.env.VUE_APP_WARNING + '80',
      ],
    }
  },
  props: {
    chartType: {
      type: String,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    dataset: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '250',
    },
    legendPosition: {
      type: String,
      default: 'bottom', // Valor por defecto
    },
    tittle: {
      type: String,
      required: true,
    },
    fixedHeight: {
      type: String,
      default: '230px',
    },
  },
  mounted() {
    this.generateBarColors()
    this.renderChart()
  },
  methods: {
    generateBarColors() {
      const labels = this.dataset.data.labels
      this.backgroundColor = []
      this.borderColor = []
      for (let index = 0; index < labels.length; index++) {
        let r = Math.floor(Math.random() * 256)
        let g = Math.floor(Math.random() * 256)
        let b = Math.floor(Math.random() * 256)

        this.backgroundColor[index] = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + '0.5)'
        this.borderColor[index] = 'rgba(' + r + ', ' + g + ', ' + b + ')'
      }
    },

    generateRandomColor() {
      // Generar valores aleatorios para R, G, B y A entre 0 y 255
      var r = Math.floor(Math.random() * 256)
      var g = Math.floor(Math.random() * 256)
      var b = Math.floor(Math.random() * 256)
      // var a = Math.random().toFixed(2) // Opacidad aleatoria entre 0 y 1, redondeada a 2 decimales

      // Devolver el color en formato RGBA
      // return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')'
      return 'rgba(' + r + ', ' + g + ', ' + b + ')'
    },

    renderChart() {
      const ctx = this.$refs.chart.getContext('2d')
      const data = this.prepareData()

      const chartOptions = {
        legend: {
          position: this.legendPosition,
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: { animateScale: true },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0,
          },
        },
      }

      const chartConfig = {
        type: this.chartType,
        data: {
          labels: data.labels,
          datasets: data.datasets,
        },
        options: chartOptions,
      }
      new Chart(ctx, chartConfig)
    },

    prepareData() {
      const labels = this.dataset.data.labels
      let datasets = []

      if (Array.isArray(this.dataset.data.series) && this.dataset.data.series.length > 0) {
        if (typeof this.dataset.data.series[0] === 'object') {
          /* datasets = this.dataset.data.series.map((serie) => ({
            label: serie.label,
            data: serie.data,
            fill: this.chartType === 'line' ? false : null,
            backgroundColor: this.randomColor(),
            borderColor: this.randomColor(),
            pointRadius: this.chartType === 'line' ? 3.5 : null,
            pointBackgroundColor: this.chartType === 'line' ? this.randomColor() : 'null',
          })) */
          let index = 0
          this.dataset.data.series.forEach((set) => {
            // let color = this.randomColor()
            let color = this.systemColor[index]
            let borderColor = this.systemBorderColor[index]
            index++
            datasets.push({
              label: set.label,
              data: set.data,
              backgroundColor: color, //this.chartType === 'bar' ? color : borderColor,
              borderColor: borderColor,
              borderWidth: this.chartType === 'bar' ? 1 : 4,
              pointBackgroundColor: borderColor,
              fill: false,
            })
          })
        } else {
          datasets.push({
            data: this.dataset.data.series,
            fill: this.chartType === 'line' ? false : null,
            label: 'Data',
            backgroundColor: this.systemColor, // this.chartType === 'bar' ? this.systemColor : this.systemBorderColor,
            borderColor: this.systemBorderColor,
            pointRadius: this.chartType === 'line' ? 3.5 : null,
            pointBackgroundColor: this.chartType === 'line' ? this.systemBorderColor : 'null',
          })
        }
      }

      return {
        labels,
        datasets,
      }
    },

    randomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
  },
}
</script>
