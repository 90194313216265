<template>
  <v-card class="v-card--word-cloud" v-bind="$attrs" v-on="$listeners" color="white">
    <v-card-title class="white--text mt-8">
      <h3 class="card-title font-weight-light mt-2 ml-2">{{ title }}</h3>
    </v-card-title>
    <v-card-text :style="{ height: fixedHeight }">
      <v-divider class="mt-2" />
      <div class="d-flex justify-center mt-4">
        <vue-word-cloud
          style="height: 205px; width: 640px"
          :words="words"
          :color="color"
          font-family="Roboto"
          text="Prueba"
        ></vue-word-cloud>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueWordCloud from 'vuewordcloud'
export default {
  name: 'WordCloudChart',
  components: { VueWordCloud },
  props: {
    title: {
      type: String,
      default: 'Word Cloud',
    },
    words: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: Array,
      default: () => [400, 400],
    },
    wordStyle: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: Function,
      default: ([, weight]) =>
        weight > 50
          ? process.env.VUE_APP_PRIMARY
          : weight > 35
          ? process.env.VUE_APP_SECONDARY
          : weight > 25
          ? process.env.VUE_APP_ACCENT
          : weight > 10
          ? process.env.VUE_APP_ERROR
          : process.env.VUE_APP_INFO,
      // weight > 50 ? process.env.VUE_APP_PRIMARY : weight > 15 ? 'DeepPink' : 'Red',
    },
    fixedHeight: {
      type: String,
      default: '230px',
    },
  },
}
</script>
